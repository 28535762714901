const defaultUsers = [
  {
    id: 1,
    name: "Shasta-Operator",
    topMemberId: 1001,
    memberId: 1001,
    isOperator: true,
    companyDb: "Shasta",
    isDefault: true,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b3BNZW1iZXJJZCI6MTAwMSwibWVtYmVySWQiOjEwMDEsImlzT3BlcmF0b3IiOnRydWUsImxvZ2luSWQiOiJ0ZWNoc2Vuc2UiLCJjb21wYW55RGIiOiJTaGFzdGEifQ.F5aWTI6vYltMCBbCHjdnnUNN7ULthgmNyjcwQ5t1dXU",
  },

  {
    id: 2,
    name: "Shasta-User-1191",
    topMemberId: 1001,
    memberId: 1191,
    loginId: "252687",
    isOperator: false,
    companyDb: "Shasta",
    isDefault: true,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b3BNZW1iZXJJZCI6MTAwMSwibWVtYmVySWQiOjExOTEsImlzT3BlcmF0b3IiOmZhbHNlLCJsb2dpbklkIjoiMjUyNjg3IiwiY29tcGFueURiIjoiU2hhc3RhIn0.j33shoXkFy2UAK6K1KvbMxf34GZN4vJhZrcSXgDKN1I",
  },

  {
    id: 3,
    name: "QUALIA-Operator",
    topMemberId: 1,
    memberId: 1,
    loginId: "panacea",
    isOperator: true,
    companyDb: "QUALIA",
    isDefault: true,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b3BNZW1iZXJJZCI6MSwibWVtYmVySWQiOjEsImxvZ2luSWQiOiJwYW5hY2VhIiwiaXNPcGVyYXRvciI6dHJ1ZSwiY29tcGFueURiIjoiUVVBTElBIn0.skF5aW-HkoZc5w_RWOUS1jbUbt--GLqnv5tnqs8zl9o",
  },

  {
    id: 4,
    name: "PenguinMobile-Operator",
    topMemberId: 1,
    memberId: 1,
    loginId: "techsense",
    isOperator: true,
    companyDb: "PenguinMobile",
    isDefault: true,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b3BNZW1iZXJJZCI6MSwibWVtYmVySWQiOjEsImlzT3BlcmF0b3IiOnRydWUsImxvZ2luSWQiOiJ0ZWNoc2Vuc2UiLCJjb21wYW55RGIiOiJQZW5ndWluTW9iaWxlIn0.xsoY1DFd_YI0eEMad-RBRrQBb1AWaeEGjOfr-mdnk2I",
  },
];

export default defaultUsers;
