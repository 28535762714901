import styled from "styled-components";

const StyledEditProfileCard = styled.div`
  width: 400px;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px dashed rgba(186, 186, 186, 0.6);
  border-radius: 6px;
  overflow: hidden;
  /* Rectangle 784 */

  .profile-header {
    width: 100%;
    height: 70px;
    position: relative;
  }

  .profile-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 15px;
  }

  .profile-pre-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 8px;

    color: rgba(99, 99, 102, 0.7);
  }

  .profile-sub-title {
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 8px;
    display: flex;
    align-items: center;
    gap: 20px;

    color: rgba(99, 99, 102, 0.7);
  }

  .header-bg {
    width: 100%;
    height: 50%;
    background: #f0f0f0;
  }

  .header-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .avatar-ball {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
  }

  .profile-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }

  .title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .user-id-data {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
  }

  .id-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    input {
      width: 100px;
      height: 30px;
      padding: 5px;
      border-radius: 3px;
      border: 1px solid rgba(186, 186, 186, 0.6);
    }
  }

  .id-value {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
  }

  .id-key {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 8px;

    color: rgba(99, 99, 102, 0.7);
  }

  .toggle-button {
    display: flex;
    align-items: center;
    border: 1px solid rgba(186, 186, 186, 0.6);
    border-radius: 5px;
    overflow: hidden;

    .selection-box {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;

      border-right: 1px solid rgba(186, 186, 186, 0.6);

      &.selected {
        color: #fff;
        background-color: #285ac7;
        font-weight: 800;
      }
    }
  }

  /* Mobile responsiveness */
  @media (max-width: 480px) {
    max-width: 320px;
    min-width: 320px;
    width: 320px;
    .profile-title {
      font-size: 16px;
    }

    .id-value {
      font-size: 16px;
    }
  }
`;

export default StyledEditProfileCard;
