import React from 'react';

const TrashIcon = ({ width = '13', height = '13', color = '#FF453A' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5357 2.32139H10.1452L9.15862 0.674654C8.88237 0.256136 8.4558 0 7.9683 0H5.0317C4.5442 0 4.09152 0.256136 3.84196 0.674654L2.85478 2.32139H0.464286C0.206752 2.32139 0 2.52828 0 2.78567V3.24995C0 3.5082 0.206752 3.71423 0.464286 3.71423H0.928571V12.9998C0.928571 14.0256 1.75993 14.8569 2.78571 14.8569H10.2143C11.2401 14.8569 12.0714 14.0256 12.0714 12.9998V3.71423H12.5357C12.794 3.71423 13 3.5082 13 3.24995V2.78567C13 2.52828 12.794 2.32139 12.5357 2.32139ZM4.98817 1.4764C5.01719 1.42562 5.07522 1.39283 5.13616 1.39283H7.86384C7.9255 1.39283 7.98354 1.42548 8.01256 1.47626L8.51964 2.32139H4.48036L4.98817 1.4764ZM10.2143 13.4641H2.78571C2.52928 13.4641 2.32143 13.2562 2.32143 12.9998V3.71423H10.6786V12.9998C10.6786 13.2551 10.4696 13.4641 10.2143 13.4641ZM6.5 12.0712C6.75663 12.0712 6.96429 11.8636 6.96429 11.607V5.57134C6.96429 5.31471 6.75663 5.10706 6.5 5.10706C6.24337 5.10706 6.03571 5.31598 6.03571 5.57134V11.607C6.03571 11.8623 6.24464 12.0712 6.5 12.0712ZM4.17857 12.0712C4.43393 12.0712 4.64286 11.8623 4.64286 11.607V5.57134C4.64286 5.31471 4.43521 5.10706 4.17857 5.10706C3.92194 5.10706 3.71429 5.31598 3.71429 5.57134V11.607C3.71429 11.8623 3.92321 12.0712 4.17857 12.0712ZM8.82143 12.0712C9.07806 12.0712 9.28571 11.8636 9.28571 11.607V5.57134C9.28571 5.31471 9.07806 5.10706 8.82143 5.10706C8.56479 5.10706 8.35714 5.31598 8.35714 5.57134V11.607C8.35714 11.8623 8.56607 12.0712 8.82143 12.0712Z"
        fill={color}
      />
    </svg>
  );
};
export default TrashIcon;
