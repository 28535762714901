import React from "react";
import styled, { css } from "styled-components";

const StyledMiniButton = styled.button`
  padding: 5px;
  border: 1px solid rgba(186, 186, 186, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      color: #aaa;
      border-color: rgba(186, 186, 186, 0.3);
      background-color: #f0f0f0;
    `}
`;

const MiniButton = ({ icon, title, disabled, ...props }) => {
  return (
    <StyledMiniButton disabled={disabled} {...props}>
      <div className="button-icon">{icon}</div>
      <div className="button-title">{title}</div>
    </StyledMiniButton>
  );
};

export default MiniButton;
