import React, { useState } from "react";
import StyledTopBar from "./style";
import IconButton from "../IconButton";
import GearIcon from "../../assets/icons/GearIcon";
import MobileIcon from "../../assets/icons/MobileIcon";
import DesktopIcon from "../../assets/icons/Desktop";
import TabletIcon from "../../assets/icons/Tablet";
import ElementPopper from "react-element-popper";
import "react-element-popper/build/element_popper.css";
import ActiveUserChip from "./ActiveUserChip";
import UserChipsCard from "../UserChipsCard";

const TopBar = ({
  activeUser,
  toggleDrawer = () => {},
  selectUser = () => {},
}) => {
  const handleToggleDrawer = () => {
    toggleDrawer();
  };

  const [openPopper, setOpenPopper] = useState(false);
  return (
    <StyledTopBar>
      <div className="block user-block">
        <ElementPopper
          element={
            <ActiveUserChip
              user={activeUser}
              onClick={() => setOpenPopper((prev) => !prev)}
            />
          }
          popper={
            <UserChipsCard activeUser={activeUser} selectUser={selectUser} />
          }
          arrow
          popperShadow
          active={openPopper}
          position="bottom-left"
        />
      </div>

      <div className="block device-block">
        <IconButton
          title="Desktop"
          icon={<DesktopIcon width="22" height="22" />}
        />
        <IconButton
          title="Tablet"
          icon={<TabletIcon width="22" height="22" />}
        />
        <IconButton
          title="Mobile"
          icon={<MobileIcon width="22" height="22" />}
        />
      </div>

      <div className="block action-block">
        <IconButton
          onClick={handleToggleDrawer}
          title="Setup"
          icon={<GearIcon width="22" height="22" />}
        />
      </div>
    </StyledTopBar>
  );
};

export default TopBar;
