import React from "react";

const MobileIcon = ({ width = "20", height = "20", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}

    >
      <path
        d="M2.6 20.8H10.4C11.8365 20.8 13 19.6365 13 18.2V2.6C13 1.1635 11.8365 0 10.4 0H2.6C1.1635 0 0 1.1635 0 2.6V18.2C0 19.6365 1.1635 20.8 2.6 20.8ZM1.3 2.6H11.7V18.2H1.3V2.6Z"
        fill="white"
      />
    </svg>
  );
};

export default MobileIcon;
