import React from "react";
import StyledProfileCard from "./style";
import AvatarIcon from "../../assets/icons/AvatarIcon";
import IconButton from "../IconButton";
import EditIcon from "../../assets/icons/EditIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import PlayIcon from "../../assets/icons/PlayIcon";
import StarIcon from "../../assets/icons/StarIcon";

const ProfileCard = ({
  user = {},
  isDefault = false,
  activeDb,
  start = () => {},
  addToFavourite = () => {},
  deleteProfile = () => {},
}) => {
  const {
    name,
    isOperator,
    topMemberId,
    memberId,
    loginId,
    companyDb,
    env,
    isFavourite,
  } = user || {};

  return (
    <StyledProfileCard>
      <div className="profile-header">
        <div className="header-bg" />

        <div className="avatar-ball">
          <AvatarIcon width="40px" height="40px" />
        </div>

        <div className="header-actions">
          <IconButton
            onClick={() => start()}
            icon={<PlayIcon width="16" height="16" />}
          />
          <IconButton
            onClick={() => addToFavourite(user)}
            icon={<StarIcon active={isFavourite} width="16" height="16" />}
          />
          {!isDefault && (
            <>
              <IconButton
                icon={<EditIcon width="18" height="18" color="#285ac7" />}
              />
              <IconButton
                onClick={() => deleteProfile(user)}
                icon={<TrashIcon width="12" height="18" color="#eb1a1a" />}
              />
            </>
          )}
        </div>
      </div>

      <div className="profile-body">
        <div className="title-wrapper">
          <div className="profile-pre-title">{env || "-"}</div>
          <div className="profile-title">{name || "-"}</div>
          <div className="profile-sub-title">
            {companyDb || "-"} |{" "}
            {isOperator === undefined
              ? "-"
              : isOperator
              ? "OPERATOR"
              : "MEMBER"}
          </div>
        </div>

        <div className="user-id-data">
          <div className="id-block">
            <span className="id-value">{topMemberId || "-"}</span>
            <span className="id-key">TopMemberId</span>
          </div>

          <div className="id-block">
            <span className="id-value">{memberId || "-"}</span>
            <span className="id-key">MemberId</span>
          </div>

          <div className="id-block">
            <span className="id-value">{loginId || "-"}</span>
            <span className="id-key">LoginId</span>
          </div>
        </div>
      </div>
    </StyledProfileCard>
  );
};

export default ProfileCard;
