import styled from "styled-components";

const StyledTopBar = styled.div`
  width: 100%;
  height: 60px;
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bababa;

  padding: 0 20px;

  .block {
    min-width: 200px;
    display: flex;
    align-items: flex-start;
    gap: 20px;

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;

    color: #fff;
  }

  .user-block {
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  .action-block {
    align-items: center;
    justify-content: flex-end;
    gap: 40px;
  }

  .device-block {
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  /* Mobile responsiveness */
  @media (max-width: 480px) {
    .block {
      min-width: fit-content;
    }

    .device-block {
      display: none;
    }
  }
`;

export default StyledTopBar;
