import React, { useEffect, useState } from "react";
import StyledCacheCard from "./style";
import CustomTreeView from "../CustomTreeView";
import Company from "../../controllers/company";
// import BaseTable from "../BaseTable";
import BaseTreeTable from "../BaseTreeTable";

const CacheConfigCard = () => {
  const [treeData, setTreeData] = useState({ name: "", children: [] });
  const [cacheData, setCacheData] = useState(false);

  const getComapnyTree = async () => {
    const tree = await Company.getCompanyTree();
    setTreeData(tree);
  };

  useEffect(() => {
    getComapnyTree();
  }, []);

  const handleSelectDb = async (db) => {
    const tableData = await Company.getCompanySeasonsWithCache(db);
    setCacheData(tableData);
  };

  return (
    <StyledCacheCard>
      <div className="companies-list">
        <CustomTreeView data={treeData} onSelect={handleSelectDb} />
      </div>
      <div className="cache-data-table-wrapper">
        {/* {cacheData && <BaseTable data={cacheData} />} */}
        {cacheData && <BaseTreeTable data={cacheData} />}
      </div>
    </StyledCacheCard>
  );
};

export default CacheConfigCard;
