import React from "react";

const ServerIcon = ({ width = "20", height = "20", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5714 12.5714H1.57143C0.706161 12.5714 0 13.2776 0 14.1429V20.4286C0 21.2938 0.706161 22 1.57143 22H23.5714C24.4367 22 25.1429 21.2938 25.1429 20.4286V14.1429C25.1429 13.2786 24.4357 12.5714 23.5714 12.5714ZM17.2857 18.4643C16.635 18.4643 16.1071 17.9364 16.1071 17.2857C16.1071 16.635 16.635 16.1071 17.2857 16.1071C17.9364 16.1071 18.4643 16.635 18.4643 17.2857C18.4643 17.9364 17.9388 18.4643 17.2857 18.4643ZM20.4286 18.4643C19.7779 18.4643 19.25 17.9364 19.25 17.2857C19.25 16.635 19.7779 16.1071 20.4286 16.1071C21.0792 16.1071 21.6071 16.635 21.6071 17.2857C21.6071 17.9364 21.0817 18.4643 20.4286 18.4643ZM23.5714 0H1.57143C0.706161 0 0 0.706161 0 1.57143V7.85714C0 8.72241 0.706161 9.42857 1.57143 9.42857H23.5714C24.4367 9.42857 25.1429 8.72241 25.1429 7.85714V1.57143C25.1429 0.706161 24.4357 0 23.5714 0ZM17.2857 5.89286C16.635 5.89286 16.1071 5.36496 16.1071 4.71429C16.1071 4.06362 16.6375 3.53571 17.2857 3.53571C17.9339 3.53571 18.4643 4.06607 18.4643 4.71429C18.4643 5.3625 17.9388 5.89286 17.2857 5.89286ZM20.4286 5.89286C19.7779 5.89286 19.25 5.36496 19.25 4.71429C19.25 4.06362 19.7804 3.53571 20.4286 3.53571C21.0768 3.53571 21.6071 4.06607 21.6071 4.71429C21.6071 5.3625 21.0817 5.89286 20.4286 5.89286Z"
        fill="white"
      />
    </svg>
  );
};

export default ServerIcon;
