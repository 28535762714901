import styled from "styled-components";

const StyledCacheCard = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  cursor: default;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;

  .companies-list {
    width: 300px;
    height: 100%;
    background: #f2f2f7;
    border-radius: 5px;
    flex-shrink: 0;
  }

  .cache-data-table-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export default StyledCacheCard;
