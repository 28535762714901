import React from "react";

const ShuffleIcon = ({ width = "25", height = "25", color = "#000" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 130 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22222 36.1105H32.5C37.8156 36.1105 42.5606 38.9344 45.8683 43.3399C48.2156 38.9777 51.1333 34.9405 54.47 31.3366C48.6706 25.3927 40.9933 21.666 32.5 21.666H7.22222C3.22833 21.666 0 24.9016 0 28.8882C0 32.8749 3.22833 36.1105 7.22222 36.1105Z"
        fill={color}
      />
      <path
        d="M62.725 58.5813C66.7044 46.614 79.0183 36.1129 89.0789 36.1129H102.353L93.015 45.4513C90.1911 48.2751 90.1911 52.8396 93.015 55.6635C94.4233 57.0718 96.2722 57.7796 98.1211 57.7796C99.97 57.7796 101.819 57.0718 103.227 55.6635L130 28.8907L103.227 2.11792C100.403 -0.705972 95.8389 -0.705972 93.015 2.11792C90.1911 4.94181 90.1911 9.50625 93.015 12.3301L102.353 21.6685H89.0789C72.6556 21.6685 55.055 35.8746 49.0172 54.0168L45.6083 64.2074C40.9789 78.1174 30.6439 86.6685 25.2778 86.6685H7.22222C3.22833 86.6685 0 89.904 0 93.8907C0 97.8774 3.22833 101.113 7.22222 101.113H25.2778C38.545 101.113 53.1772 87.2101 59.3161 68.7646L62.725 58.5813Z"
        fill={color}
      />
      <path
        d="M93.0148 67.1179C90.1909 69.9418 90.1909 74.5062 93.0148 77.3301L102.353 86.6685H85.4676C76.3098 86.6685 68.6398 80.2335 66.0326 71.444C64.1837 76.8607 61.5043 82.2701 58.1604 87.2246C64.5593 95.6168 74.3598 101.113 85.4676 101.113H102.353L93.0148 110.451C90.1909 113.275 90.1909 117.84 93.0148 120.663C94.4232 122.072 96.2721 122.78 98.121 122.78C99.9698 122.78 101.819 122.072 103.227 120.663L130 93.8907L103.227 67.1179C100.403 64.294 95.8387 64.294 93.0148 67.1179Z"
        fill={color}
      />
    </svg>
  );
};

export default ShuffleIcon;
