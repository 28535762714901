import React from "react";

const PlayIcon = ({ width = "20", height = "20", color = "#40d31c" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 122 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M115.042 56.2143L20.8839 1.86266C18.7276 0.617245 16.3283 0 13.9208 0C11.5146 0 9.11528 0.61861 6.96175 1.86402C2.6506 4.34939 0 8.94595 0 13.9235V122.639C0 127.615 2.64923 132.209 6.96175 134.697C9.11528 135.943 11.5146 136.558 13.9208 136.558C16.3283 136.558 18.7276 135.943 20.8839 134.697L115.042 80.3319C119.348 77.8438 122 73.25 122 68.2711C122 63.2962 119.349 58.7024 115.042 56.2143Z"
        fill={color}
      />
    </svg>
  );
};

export default PlayIcon;
