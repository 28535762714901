import styled from "styled-components";

const StyledSettings = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  .content-continer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .header {
    width: 100%;
    height: 35px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 8px;

    color: #000;
  }

  .main-menu {
    height: 100%;
    /* width: 50px; */
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: #f2f2f7;
    padding: 10px;
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    /* background: #fff; */
    padding: 5px;
    border-radius: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 8px;
    cursor: pointer;
    color: #000;

    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  }

  .selected {
    background: none;
    border: 1px solid #0651fe;
    box-shadow: none;
  }

  /* Mobile responsiveness */
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;

    .main-menu {
      height: fit-content;
      width: 100%;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      background: #f2f2f7;
      padding: 10px;
    }

    .icon {
      /* display: none; */
      width: 20px;
      height: 20px;
    }

    .menu-item {
      padding: 10px;
      flex-direction: row;
      align-items: center;
    }

  }
`;

export default StyledSettings;
