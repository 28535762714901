import React from "react";
import StyledSettings from "./style";
import DBIcon from "../../assets/icons/DBIcon";
import AvatarIcon from "../../assets/icons/AvatarIcon";
import CacheConfigCard from "../CacheConfigCard";
import UserProfilePage from "../UserProfilePage";

const SettingsPage = ({ currentPage, changePage, selectUser }) => {
  // const header =
  //   currentPage === "userProfile"
  //     ? "User Profile"
  //     : currentPage === "cache"
  //     ? "Cache Configuration"
  //     : "-";

  return (
    <StyledSettings>
      <div className="main-menu">
        <button
          onClick={() => changePage("userProfile")}
          className={`menu-item ${currentPage === "userProfile" && "selected"}`}
        >
          <AvatarIcon
            className="icon"
            width="30"
            height="30"
            color={currentPage === "userProfile" ? "#0651FE" : "#636366"}
          />
          <span>Profile</span>
        </button>
        <button
          onClick={() => changePage("cache")}
          className={`menu-item ${currentPage === "cache" && "selected"}`}
        >
          <DBIcon
            className="icon"
            color={currentPage === "cache" ? "#0651FE" : "#636366"}
            width="30"
            height="30"
          />
          <span>Cache</span>
        </button>
      </div>

      <div className="content-continer">
        {/* <div className="header">{header}</div> */}
        {currentPage === "userProfile" ? (
          <UserProfilePage selectUser={selectUser} />
        ) : (
          <CacheConfigCard />
        )}
      </div>
    </StyledSettings>
  );
};

export default SettingsPage;
