import React from "react";

const AvatarIcon = ({
  width = "29",
  height = "29",
  color = "#636366",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <circle cx="14.1323" cy="14.1323" r="14.1323" fill="#F4F4F4" /> */}
      {/* <circle cx="14.1323" cy="14.1323" r="14.1323" fill="#F4F4F4" /> */}
      <path
        d="M14.1323 0C6.32556 0 0 6.32556 0 14.1323C0 21.939 6.32556 28.2646 14.1323 28.2646C21.939 28.2646 28.2646 21.939 28.2646 14.1323C28.2646 6.32556 21.939 0 14.1323 0ZM14.1323 27.0891C10.1508 27.0891 6.58374 25.2818 4.20571 22.4486C5.41511 21.9254 8.43181 20.8383 10.341 20.2812C10.4905 20.2336 10.5177 20.2268 10.5177 19.5542C10.5177 18.834 10.4361 18.3244 10.2595 17.9507C10.0217 17.4411 9.73633 16.5783 9.63441 15.8037C9.34905 15.4708 8.96177 14.8185 8.71038 13.5684C8.49296 12.4677 8.59488 12.0668 8.73756 11.6931C8.75115 11.6523 8.77153 11.6116 8.77832 11.5708C8.83268 11.3194 8.75794 9.97413 8.5677 8.93459C8.43861 8.22119 8.60167 6.70604 9.58685 5.44908C10.2051 4.65414 11.3942 3.68255 13.5276 3.54666H14.7166C16.884 3.68255 18.073 4.65414 18.6913 5.44908C19.6765 6.70604 19.8396 8.22119 19.7037 8.93459C19.5134 9.97413 19.4387 11.3126 19.493 11.5708C19.4998 11.6116 19.5202 11.6523 19.5338 11.6863C19.6765 12.06 19.7852 12.4609 19.561 13.5616C19.3096 14.8117 18.9223 15.464 18.637 15.7969C18.535 16.5715 18.2497 17.4276 18.0119 17.9439C17.7877 18.4127 17.5634 18.9699 17.5634 19.527C17.5634 20.1997 17.5906 20.2065 17.7469 20.254C19.561 20.7908 22.6864 21.8711 24.0657 22.435C21.6876 25.2751 18.1206 27.0891 14.1323 27.0891Z"
        fill={color}
      />
    </svg>
  );
};

export default AvatarIcon;
