import React, { useState } from "react";
import StyledEditProfileCard from "./style";
import AvatarIcon from "../../assets/icons/AvatarIcon";
import IconButton from "../IconButton";
import TrashIcon from "../../assets/icons/TrashIcon";
import SaveIcon from "../../assets/icons/SaveIcon";
import User from "../../controllers/user";

const EditProfileCard = ({
  activeDb,
  close = () => {},
  saveProfile = () => {},
}) => {
  const [isOperator, setIsOperator] = useState(true);
  const [topMemberId, setTopMemberId] = useState("");
  const [memberId, setMemberId] = useState("");
  const [loginId, setLoginId] = useState("");
  const [showError, setShowError] = useState(false);

  const { name: dbName, metadata } = activeDb || {};
  const { env } = metadata || {};

  const handleSaveProfile = () => {
    if (memberId == "" || loginId == "" || topMemberId == "") {
      setShowError(true);
      return;
    }
    const user = {
      topMemberId,
      loginId,
      memberId,
      isOperator,
      companyDb: dbName,
      isDefault: "false",
      env,
    };
    const newUser = new User(user);
    saveProfile(newUser);
  };

  return (
    <StyledEditProfileCard>
      <div className="profile-header">
        <div className="header-bg" />

        <div className="avatar-ball">
          <AvatarIcon width="40px" height="40px" />
        </div>

        <div className="header-actions">
          <IconButton
            onClick={() => handleSaveProfile()}
            icon={<SaveIcon width="18" height="18" color="#7ff14a" />}
          />
          <IconButton
            onClick={() => close()}
            icon={<TrashIcon width="12" height="18" color="#eb1a1a" />}
          />
        </div>
      </div>

      <div className="profile-body">
        <div className="title-wrapper">
          <div className="profile-pre-title">{env || "-"}</div>
          <div className="profile-title">{`${dbName}-${
            isOperator ? "Operator" : "Member"
          }-${memberId}`}</div>
          <div className="profile-sub-title">
            <span> {dbName || "-"} </span>
            <div className="toggle-button">
              <button
                onClick={() => setIsOperator(true)}
                className={`selection-box ${isOperator && "selected"}`}
              >
                OPERATOR
              </button>
              <button
                onClick={() => setIsOperator(false)}
                className={`selection-box ${!isOperator && "selected"}`}
              >
                MEMBER
              </button>
            </div>
          </div>
        </div>

        <div className="user-id-data">
          <div className="id-block">
            <span className="id-key">TopMemberId*</span>
            <input
              onChange={(e) => setTopMemberId(e.target.value)}
              type="text"
              id="input1"
            />
          </div>

          <div className="id-block">
            <span className="id-key">MemberId*</span>
            <input
              onChange={(e) => setMemberId(e.target.value)}
              type="text"
              id="input1"
            />
          </div>

          <div className="id-block">
            <span className="id-key">LoginId*</span>
            <input
              onChange={(e) => setLoginId(e.target.value)}
              type="text"
              id="input1"
            />
          </div>
        </div>
        {showError && <span>Enter all required fields*</span>}
      </div>
    </StyledEditProfileCard>
  );
};

export default EditProfileCard;
