import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { dummyUsers } from "../../utils/dummyUsers";
import ProfileCard from "../ProfileCard";
import User from "../../controllers/user";

const StyledUserChipsCard = styled.div`
  width: 400px;
  height: 800px;
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 8px;
  cursor: default;
  box-shadow: 0px 4.79487px 14.3846px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  overflow: auto;

  .chips-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 8px;

    color: rgba(99, 99, 102, 0.7);
  }
`;

const UserChipsCard = ({ activeUser, selectUser = () => {} }) => {
  const [favourites, setFavourties] = useState([]);

  const getFavourites = () => {
    const profiles = User.getFavourtieProfiles();
    setFavourties(profiles);
  };

  useEffect(() => {
    getFavourites();
  }, []);

  return (
    <StyledUserChipsCard>
      {activeUser && (
        <>
          <div className="title">Active Profile</div>

          <div className="chips-list">
            <ProfileCard
              user={activeUser}
              start={() => selectUser(activeUser)}
            />
          </div>
        </>
      )}

      <div className="title">Favourite Profiles</div>
      <div className="chips-list">
        {Array.isArray(favourites) &&
          favourites.map((user) => {
            return (
              <ProfileCard
                key={user.id}
                user={user}
                start={() => selectUser(user)}
              />
            );
          })}
      </div>
    </StyledUserChipsCard>
  );
};

export default UserChipsCard;
