import { TreeState, TreeTable } from "cp-react-tree-table";
import React, { useState } from "react";
import "./style.css";
import IconButton from "../IconButton";

const MOCK_DATA = [
  {
    data: {
      name: "Company I",
      expenses: "105,000",
      employees: "22",
      contact: "Makenzie Higgs",
    },
    children: [
      {
        data: {
          name: "Department 1",
          expenses: "75,000",
          employees: "18",
          contact: "Florence Carter",
        },
      },
    ],
  },
];

const renderNameCell = (row) => {
  return (
    <div
      style={{ paddingLeft: row.metadata.depth * 15 + "px" }}
      className={
        row.metadata.hasChildren ? "with-children" : "without-children"
      }
    >
      {row.metadata.hasChildren ? (
        <button className="toggle-button" onClick={row.toggleChildren}></button>
      ) : (
        ""
      )}

      <span>{row.data.name}</span>
    </div>
  );
};

const renderLengthCell = (row) => {
  return <span>{row.data.length}</span>;
};

const renderSizeCell = (row) => {
  return <span>{row.data.size}</span>;
};

const renderSyncCell = (row) => {
  return <span>{row.data.sync_date}</span>;
};

const renderActionCell = (row) => {
  return (
    <span>
      <IconButton />
    </span>
  );
};

const BaseTreeTable = ({ data = MOCK_DATA }) => {
  const [treeData, setTreeData] = useState(TreeState.create(data));
  console.log("tree data for tree table", data, treeData);

  const handleOnChange = (newValue) => {
    setTreeData(newValue);
  };
  return (
    <TreeTable value={treeData} onChange={handleOnChange}>
      <TreeTable.Column
        basis="180px"
        grow="0"
        renderCell={renderNameCell}
        renderHeaderCell={() => <span>Name</span>}
      />
      <TreeTable.Column
        renderCell={renderLengthCell}
        renderHeaderCell={() => <span>Length</span>}
      />
      <TreeTable.Column
        renderCell={renderSizeCell}
        renderHeaderCell={() => <span>Size</span>}
      />
      <TreeTable.Column
        renderCell={renderSyncCell}
        renderHeaderCell={() => <span>Last Synched</span>}
      />
      <TreeTable.Column
        renderCell={renderActionCell}
        renderHeaderCell={() => <span>Action</span>}
      />
    </TreeTable>
  );
};

export default BaseTreeTable;
