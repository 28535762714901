import styled from "styled-components";

const StyledProfileCard = styled.div`
  /* width: 350px; */
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgba(186, 186, 186, 0.6);
  border-radius: 6px;
  overflow: hidden;
  /* Rectangle 784 */

  .profile-header {
    width: 100%;
    height: 70px;
    position: relative;
  }

  .profile-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000;
  }

  .profile-sub-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 8px;

    color: rgba(99, 99, 102, 0.7);
  }

  .profile-pre-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 8px;

    color: rgba(99, 99, 102, 0.7);
  }

  .header-bg {
    width: 100%;
    height: 50%;
    background: #f0f0f0;
  }

  .header-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .avatar-ball {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
  }

  .profile-body {
    padding: 5px 40px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .user-id-data {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
  }

  .id-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .id-value {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
  }

  .id-key {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 8px;

    color: rgba(99, 99, 102, 0.7);
  }

  /* Mobile responsiveness */
  @media (max-width: 480px) {
    max-width: 320px;
    min-width: 320px;
    width: 320px;

    .profile-title {
      font-size: 16px;
    }

    .id-value {
      font-size: 16px;
    }
  }
`;

export default StyledProfileCard;
