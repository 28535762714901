// import logo from "./logo.svg";
import { useEffect, useRef, useState } from "react";
import "./App.css";
import "react-modern-drawer/dist/index.css";
import TopBar from "./components/TopBar";
import CustomModal from "./components/CustomModal";
import "react-responsive-modal/styles.css";
import SettingsPage from "./components/SettingsPage";
import { clientUrl } from "./utils/globalConstants";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const selectedUserRef = useRef(selectedUser);
  const [isLoading, setIsLoading] = useState(false);
  const [settingsPage, setSettingsPage] = useState("userProfile");

  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser === null) return;
    setIsLoading(true);
    const iframe = document.getElementById("mlm-tree-frame");

    const { env } = selectedUser;
    const envUrl = clientUrl[env];

    const handleMessage = () => {
      const accessToken = selectedUserRef.current?.token;

      const dataToSend = {
        source: "panacea",
        type: "accessToken",
        payload: { accessToken },
      };
      iframe.contentWindow.postMessage(dataToSend, envUrl);
      setIsLoading(false);
    };

    iframe.addEventListener("load", handleMessage);

    iframe.src = envUrl;
    return () => {
      iframe.removeEventListener("load", handleMessage);
    };
  }, [selectedUser]);

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectUser = (user) => {
    if (!user) return;
    user.select();
    setIsOpen(false);
    setSelectedUser(user);
  };

  const handleChangeSettingsPage = (page) => {
    setSettingsPage(page);
  };

  return (
    <>
      <div className="App">
        <TopBar toggleDrawer={toggleDrawer} activeUser={selectedUser} selectUser={handleSelectUser} />
        <CustomModal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
          <SettingsPage
            changePage={handleChangeSettingsPage}
            currentPage={settingsPage}
            selectUser={handleSelectUser}
          />
        </CustomModal>

        <div className="iframe-container">
          {isLoading && (
            // <img className="loading-gif" src="/402107790_STATIC_NOISE_400.gif" alt="static-gif" />
            <img className="loading-gif" src="/71B.gif" alt="static-gif" />
          )}
          {selectedUser ? (
            <iframe
              hidden={isLoading}
              title="proflow-dev-frame"
              // src={iframeSrc}
              id="mlm-tree-frame"
              className="tree-frame"
              allowFullScreen
            ></iframe>
          ) : (
            <div className="no-user-info">Select a user profile</div>
          )}
        </div>
      </div>
    </>
  );
}

export default App;
