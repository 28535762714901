import styled from "styled-components";

const StyledUserProflePage = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  overflow: auto;

  .companies-list {
    height: 100%;
    background: #f2f2f7;
    border-radius: 5px;
    flex-shrink: 0;
  }

  .profiles-list-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 50px;
  }

  .profiles-sub-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      color: #636366;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .cards-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
  }

  .tree-view-container {
    width: 300px;
    height: 100%;
  }

  .no-company-selected {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    color: #aaa;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  /* Mobile responsiveness */
  @media (max-width: 480px) {
    position: relative;

    .profiles-list-wrapper {
      padding: 10px 20px 50px 30px;
    }

    .tree-view-container {
      transition: transform 0.3s ease;
    }

    /* Hide the tree-view-container when "hide" class is applied */
    .tree-view-container.hide {
      width: 0;
      transform: translateX(-100%);

      .ide {
        transform: translateX(-200%);
      }
    }

    /* Show the tree-view-container when "show" class is applied */
    .tree-view-container.show {
      width: 250px;
      transform: translateX(0);
    }

    .companies-list {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      z-index: 10;
    }

    .toggle-bar {
      width: 20px;
      height: 100%;
      background: #f2f2f7;
      border-radius: 0.4em;
      border: 1px solid #aaa;

      &:hover {
        background: #0651fe;
      }
    }
  }
`;

export default StyledUserProflePage;
