import React from 'react';

const AddIcon = ({ width = '20', height = '20', color = '#007AFF' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 12H16V2C16 0.896 15.104 0 14 0C12.896 0 12 0.896 12 2V12H2C0.896 12 0 12.896 0 14C0 15.104 0.896 16 2 16H12V26C12 27.104 12.896 28 14 28C15.104 28 16 27.104 16 26V16H26C27.104 16 28 15.104 28 14C28 12.896 27.104 12 26 12Z"
        fill={color}
      />
    </svg>
  );
};

export default AddIcon;
