import React from "react";

const DesktopIcon = ({ width = "20", height = "20", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}

    >
      <g clipPath="url(#clip0_853_3188)">
        <path
          d="M1.81315 0H18.1616C19.1648 0 19.9999 0.791785 19.9999 1.80139V13.7939C19.9999 14.2839 19.8011 14.7525 19.4575 15.0934C19.116 15.4321 18.6496 15.6297 18.1616 15.6297H13.1607C13.2834 17.3755 13.9757 18.4123 14.0093 18.4621C14.1796 18.6973 14.2014 19.0105 14.0697 19.2695C13.9377 19.5292 13.6682 19.6953 13.3768 19.6953H6.59792C6.30633 19.6953 6.0367 19.5235 5.90476 19.2635C5.77322 19.0042 5.79526 18.7022 5.96609 18.4672C6.00036 18.4165 6.69376 17.3755 6.81676 15.6297H1.81314C0.796856 15.6297 -2.67029e-05 14.7939 -2.67029e-05 13.7939V1.80139C-1.52588e-05 0.791785 0.796867 0 1.81315 0ZM18.1616 1.54657H1.81315C1.65608 1.54657 1.54655 1.65073 1.54655 1.80139V10.0996H18.4534V1.80139C18.4534 1.64282 18.3136 1.54657 18.1616 1.54657ZM7.84135 18.1488H12.1348C11.8979 17.5278 11.6695 16.6842 11.6095 15.6297H8.36802C8.3078 16.6842 8.07889 17.5278 7.84135 18.1488ZM1.81315 14.0832H18.1616C18.3187 14.0832 18.4534 13.9477 18.4534 13.7939V11.6461H1.54655V13.7939C1.54655 13.9402 1.65056 14.0832 1.81315 14.0832Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_853_3188">
          <rect
            width="20"
            height="19.6953"
            fill="white"
            transform="matrix(-1 0 0 1 19.9999 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DesktopIcon;
