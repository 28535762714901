import React from "react";
import Modal from "react-responsive-modal";
import "./style.css";

const CustomModal = ({ isOpen, closeModal, children }) => {
  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      center
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
      }}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
