import styled from "styled-components";

const StyledIconButton = styled.button`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
`;

const IconButton = ({icon, ...props}) => {
    return (
        <StyledIconButton {...props} >
            {icon}
        </StyledIconButton>
    )
}

export default IconButton;