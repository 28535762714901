import { fetchData } from "../utils/async";
import { EndpointRoutes, getEndpoint } from "../utils/globalConstants";
import { CreateUUIDWithoutDash } from "../utils/helper";
import Company from "./company";
import defaultUsers from "./defaultUsers";
import sign from "jwt-encode";

class User {
  static allUsers = [];

  _tokenSecret =
    "15df181ebda9897193cd2dde03ede3feb6502112006e0998c6a3f4807f6884b4d51070fff207a1e9801d9252a72ad81db66e3b65527c5f8e570441c5bab91664";

  constructor(data) {
    const {
      id,
      name,
      topMemberId,
      memberId,
      loginId,
      isOperator,
      companyDb,
      token,
      isDefault,
      env,
      isFavourite,
    } = data;
    this.id = id || CreateUUIDWithoutDash();

    this.topMemberId = topMemberId;
    this.loginId = loginId;
    this.isOperator = isOperator;
    this.memberId = memberId;
    this.companyDb = companyDb;
    this.env = env;
    this.isDefault = isDefault || false;

    this.isFavourite = isFavourite;
    this.token = token || this.generateJwtToken();
    this.name = name || this.generateName();
  }

  select() {
    localStorage.setItem("active_user", JSON.stringify(this));
  }

  unselect() {
    this.isActive = false;
  }

  delete() {
    const allUsers = [...User.allUsers];
    const newUsers = allUsers.filter((u) => u.id !== this.id);
    User.allUsers = newUsers;
    User.syncLocalDb();
    return newUsers;
  }

  generateJwtToken() {
    const token = sign(
      {
        topMemberId: this.topMemberId,
        memberId: this.memberId,
        loginId: this.loginId,
        isOperator: this.isOperator,
        companyDb: this.companyDb,
      },
      this._tokenSecret
    );
    return token;
  }

  generateName() {
    return `${this.companyDb}-${this.isOperator ? "Operator" : "Member"}-${
      this.memberId
    }`;
  }

  static sync() {
    if (this.allUsers.length > 0) return this.allUsers;
    const users = localStorage.getItem("users");
    if (!users) {
      this.addDefaultUsers();
    }
    this.loadUsers();
    return this.allUsers;
  }

  static addDefaultUsers() {
    const allDefaultUsers = defaultUsers;
    localStorage.setItem("users", JSON.stringify(allDefaultUsers));
  }

  static loadUsers() {
    const allUsers = JSON.parse(localStorage.getItem("users"));
    for (const user of allUsers) {
      const newUser = new User(user);
      this.allUsers.push(newUser);
    }
    this.allUsers[0].isActive = true;
  }

  static saveNewUser(user) {
    this.allUsers = [...this.allUsers, user];
    localStorage.setItem("users", JSON.stringify(this.allUsers));
    return this.allUsers;
  }

  static syncLocalDb() {
    localStorage.setItem("users", JSON.stringify(this.allUsers));
  }

  static async getUserProfiles(dbName, env) {
    try {
      const response = await fetchData(
        getEndpoint(env, `${EndpointRoutes.GET_USER_PROFILES}/${dbName}`)
      );
      const { data } = response;
      const { member, operator } = data;
      const memberProfile = new User({ ...member, env, isDefault: true });
      const operatorProfile = new User({ ...operator, env, isDefault: true });

      return { memberProfile, operatorProfile };
    } catch (error) {
      console.error("Error occured while getting user profiles", error);
    }
  }

  addToFavourite() {
    if (this.isFavourite) {
      return this.removeFromFavourite();
    }
    this.isFavourite = true;
    User.updateFavouriteProfiles(this);
    User.updateStoredProfiles(this);
    return Company.getCompanyCustomProfiles(this.companyDb, this.env);
  }

  removeFromFavourite() {
    this.isFavourite = false;
    User.updateFavouriteProfiles(this);
    User.updateStoredProfiles(this);
    return Company.getCompanyCustomProfiles(this.companyDb, this.env);
  }

  static updateFavouriteProfiles(user) {
    let favouriteProfiles = JSON.parse(
      localStorage.getItem("favourite_profiles") || "[]"
    );

    if (user.isFavourite) {
      // Add to favourites if not already present
      if (!favouriteProfiles.find((u) => u.id === user.id)) {
        favouriteProfiles.push(user);
      }
    } else {
      // Remove from favourites if no longer marked as favourite
      favouriteProfiles = favouriteProfiles.filter((u) => u.id !== user.id);
    }

    localStorage.setItem(
      "favourite_profiles",
      JSON.stringify(favouriteProfiles)
    );
  }

  static updateStoredProfiles(user) {
    // Retrieve `user_profiles` from localStorage or initialize to an empty array if none exist
    let userProfiles = JSON.parse(
      localStorage.getItem("user_profiles") || "[]"
    );

    // Find or create the environment profile
    let envProfile = userProfiles.find((profile) => profile.env === user.env);
    if (!envProfile) {
      envProfile = { env: user.env, companies: [] };
      userProfiles.push(envProfile);
    }

    // Find or create the company within the environment
    let companyProfile = envProfile.companies.find(
      (company) => company.name === user.companyDb
    );
    if (!companyProfile) {
      companyProfile = { name: user.companyDb, profiles: [] };
      envProfile.companies.push(companyProfile);
    }

    // Find the existing user profile within the company profiles, if it exists
    const profileIndex = companyProfile.profiles.findIndex(
      (profile) => profile.id === user.id
    );

    if (profileIndex !== -1) {
      // Update the existing profile
      companyProfile.profiles[profileIndex] = {
        ...companyProfile.profiles[profileIndex],
        isFavourite: user.isFavourite,
      };
    } else {
      // Add the new profile to the profiles array
      companyProfile.profiles.unshift({ ...user });
    }

    // Save updated profiles back to localStorage
    localStorage.setItem("user_profiles", JSON.stringify(userProfiles));

    // Update `active_user` if the current user is the active user
    const activeUser = JSON.parse(localStorage.getItem("active_user"));
    if (activeUser && activeUser.id === user.id) {
      localStorage.setItem(
        "active_user",
        JSON.stringify({ ...activeUser, isFavourite: user.isFavourite })
      );
    }
  }

  deleteProfile() {
    this.deleteFromUserProfiles();
    this.removeFromFavourites();
    return Company.getCompanyCustomProfiles(this.companyDb, this.env);
  }

  deleteFromUserProfiles() {
    // Retrieve user_profiles from localStorage
    let userProfiles = JSON.parse(
      localStorage.getItem("user_profiles") || "[]"
    );

    // Find the environment profile
    userProfiles.forEach((envProfile) => {
      // Filter out the company profile
      envProfile.companies.forEach((companyProfile) => {
        // Filter out the profile from the company's profiles
        companyProfile.profiles = companyProfile.profiles.filter(
          (profile) => profile.id !== this.id
        );
      });
    });

    // Save the updated profiles back to localStorage
    console.log("updatedprofiles", userProfiles);
    // Save the updated profiles back to `localStorage`
    localStorage.setItem("user_profiles", JSON.stringify(userProfiles));
  }

  removeFromFavourites() {
    // Retrieve favourite_profiles from localStorage
    let favouriteProfiles = JSON.parse(
      localStorage.getItem("favourite_profiles") || "[]"
    );

    // Remove the profile from favourites if it exists
    favouriteProfiles = favouriteProfiles.filter((u) => u.id !== this.id);

    // Save the updated favourites back to localStorage
    localStorage.setItem(
      "favourite_profiles",
      JSON.stringify(favouriteProfiles)
    );
  }

  static getFavourtieProfiles() {
    let favouriteProfiles = JSON.parse(
      localStorage.getItem("favourite_profiles") || "[]"
    );

    const userInstances = favouriteProfiles.map((profile) => new User(profile));
    return userInstances;
  }
}

export default User;
