import styled from "styled-components";
import React from "react";
import AvatarIcon from "../../assets/icons/AvatarIcon";

const StyledUserChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const ActiveUserChip = ({ user, ...props }) => {
  const { name } = user || {};
  return (
    <StyledUserChip {...props}>
      <AvatarIcon color="#fff" />
      <p>{name}</p>
    </StyledUserChip>
  );
};

export default ActiveUserChip;
