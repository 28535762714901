import React from 'react';

const SaveIcon = ({ width = '15', height = '14', color = '#34C759' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0625 7.5C13.9394 7.49993 13.8174 7.52413 13.7037 7.57122C13.5899 7.61831 13.4865 7.68736 13.3994 7.77443C13.3124 7.8615 13.2433 7.96487 13.1962 8.07865C13.1491 8.19242 13.1249 8.31436 13.125 8.43749V11.25H1.875V8.43749C1.875 8.18886 1.77623 7.9504 1.60041 7.77459C1.4246 7.59877 1.18614 7.5 0.9375 7.5C0.68886 7.5 0.450403 7.59877 0.274588 7.77459C0.0987722 7.9504 1.50705e-07 8.18886 1.50705e-07 8.43749V12.1875C-6.96845e-05 12.3106 0.0241318 12.4325 0.0712209 12.5463C0.11831 12.6601 0.187363 12.7635 0.274432 12.8505C0.361501 12.9376 0.464878 13.0067 0.578652 13.0537C0.692426 13.1008 0.814366 13.125 0.9375 13.125H14.0625C14.1856 13.125 14.3076 13.1008 14.4213 13.0537C14.5351 13.0067 14.6385 12.9376 14.7256 12.8505C14.8126 12.7635 14.8817 12.6601 14.9288 12.5463C14.9759 12.4325 15.0001 12.3106 15 12.1875V8.43749C15.0001 8.31436 14.9759 8.19242 14.9288 8.07865C14.8817 7.96487 14.8126 7.8615 14.7256 7.77443C14.6385 7.68736 14.5351 7.61831 14.4213 7.57122C14.3076 7.52413 14.1856 7.49993 14.0625 7.5Z"
        fill={color}
      />
      <path
        d="M6.83717 9.10029C6.9242 9.18736 7.02754 9.25643 7.14127 9.30356C7.255 9.35068 7.37691 9.37494 7.50002 9.37494C7.62313 9.37494 7.74503 9.35068 7.85876 9.30356C7.97249 9.25643 8.07583 9.18736 8.16286 9.10029L10.9754 6.2878C11.1503 6.11184 11.2484 5.87367 11.2481 5.6255C11.2477 5.37734 11.149 5.13944 10.9735 4.96397C10.798 4.78849 10.5601 4.68975 10.312 4.68941C10.0638 4.68906 9.82564 4.78714 9.64967 4.96212L8.43752 6.17427V0.937494C8.43752 0.688855 8.33874 0.4504 8.16293 0.274586C7.98711 0.0987714 7.74866 0 7.50002 0C7.25137 0 7.01292 0.0987714 6.8371 0.274586C6.66129 0.4504 6.56252 0.688855 6.56252 0.937494V6.17427L5.35036 4.96212C5.17439 4.78714 4.93622 4.68906 4.68806 4.68941C4.43989 4.68975 4.20199 4.78849 4.02651 4.96397C3.85104 5.13944 3.7523 5.37734 3.75195 5.6255C3.75161 5.87367 3.84968 6.11184 4.02467 6.2878L6.83717 9.10029Z"
        fill={color}
      />
    </svg>
  );
};

export default SaveIcon;
