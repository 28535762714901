import React from "react";

const TabletIcon = ({ width = "20", height = "20", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_853_3184)">
        <path
          d="M14.1809 0H1.82301C0.822678 0 0 0.783638 0 1.78626V18.1061C0 19.1194 0.822678 19.9649 1.82301 19.9649H14.1809C15.1867 19.9649 16 19.1194 16 18.1061V1.78626C16 0.783638 15.1867 0 14.1809 0ZM1.82301 1.54386H14.1809C14.3371 1.54386 14.4561 1.63329 14.4561 1.78626V14.7135H1.54386V1.78626C1.54386 1.63076 1.66988 1.54386 1.82301 1.54386ZM14.1809 18.4211H1.82301C1.67487 18.4211 1.54386 18.2671 1.54386 18.1061V16.2573H14.4561V18.1061C14.4561 18.2644 14.3319 18.4211 14.1809 18.4211Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_853_3184">
          <rect width="16" height="19.9649" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TabletIcon;
