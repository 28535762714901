import React from "react";

const DBIcon = ({ width = "20", height = "20", color="white" ,  ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.375 10.5V15.75C4.375 15.75 4.375 18.375 10.5 18.375C16.625 18.375 16.625 15.75 16.625 15.75V10.5"
        stroke={color}
        strokeWidth="1.3125"
      />
      <path
        d="M4.375 5.25V10.5C4.375 10.5 4.375 13.125 10.5 13.125C16.625 13.125 16.625 10.5 16.625 10.5V5.25"
        stroke={color}
        strokeWidth="1.3125"
      />
      <path
        d="M10.5 2.625C16.625 2.625 16.625 5.25 16.625 5.25C16.625 5.25 16.625 7.875 10.5 7.875C4.375 7.875 4.375 5.25 4.375 5.25C4.375 5.25 4.375 2.625 10.5 2.625Z"
        stroke={color}
        strokeWidth="1.3125"
      />
    </svg>
  );
};

export default DBIcon;
