import React from "react";

const StarIcon = ({ active = false, width = "20", height = "20" }) => {
  if (active) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 137 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.6239 41.8137C46.6239 41.8137 20.8371 44.6698 3.63505 46.5794C2.08458 46.7671 0.713645 47.8034 0.199542 49.3784C-0.314561 50.9533 0.199541 52.5936 1.34199 53.6299C14.1211 65.2911 33.3225 82.7543 33.3225 82.7543C33.3061 82.7543 28.0509 108.157 24.5582 125.107C24.2645 126.641 24.8194 128.265 26.1577 129.236C27.4878 130.207 29.2015 130.223 30.5479 129.464C45.5957 120.912 68.1346 108.051 68.1346 108.051C68.1346 108.051 90.6817 120.912 105.705 129.472C107.076 130.223 108.79 130.207 110.12 129.236C111.458 128.265 112.013 126.641 111.711 125.115C108.218 108.157 102.971 82.7543 102.971 82.7543C102.971 82.7543 122.173 65.2911 134.952 53.6544C136.094 52.5854 136.6 50.9452 136.094 49.3784C135.588 47.8116 134.217 46.7752 132.667 46.5957C115.465 44.6698 89.6698 41.8137 89.6698 41.8137C89.6698 41.8137 78.9797 18.165 71.8557 2.39915C71.1866 0.987406 69.783 0 68.1346 0C66.4862 0 65.0744 0.995566 64.4379 2.39915C57.3058 18.165 46.6239 41.8137 46.6239 41.8137Z"
          fill="#ebee49"
          stroke="#000"
          strokeWidth="2"
        />
      </svg>
    );
  }

  return (
    <svg
      width={height}
      height={height}
      viewBox="0 0 136 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.845 129.843C106.008 129.846 105.184 129.644 104.444 129.253L68.0002 110.088L31.5499 129.253C30.7004 129.698 29.7437 129.896 28.7875 129.826C27.8314 129.755 26.9139 129.419 26.1385 128.855C25.3632 128.292 24.7608 127.522 24.3992 126.634C24.0377 125.746 23.9315 124.775 24.0925 123.83L31.056 83.2428L1.55542 54.4969C0.867453 53.8269 0.380731 52.9777 0.15031 52.0454C-0.0801114 51.1131 -0.0450346 50.135 0.251572 49.2216C0.548179 48.3082 1.09448 47.4961 1.82867 46.877C2.56285 46.258 3.45563 45.8568 4.40599 45.7188L45.1613 39.7981L63.3865 2.86752C63.8115 2.00675 64.4688 1.28203 65.2841 0.775289C66.0994 0.26855 67.0403 0 68.0002 0C68.9602 0 69.901 0.26855 70.7164 0.775289C71.5317 1.28203 72.189 2.00675 72.614 2.86752L90.8391 39.7981L131.591 45.7188C132.542 45.8563 133.435 46.2571 134.169 46.8759C134.904 47.4947 135.451 48.3068 135.748 49.2203C136.045 50.1338 136.08 51.1121 135.85 52.0446C135.62 52.9772 135.133 53.8266 134.445 54.4969L104.945 83.2428L111.908 123.83C112.034 124.568 111.998 125.325 111.801 126.048C111.605 126.771 111.253 127.442 110.77 128.015C110.287 128.587 109.685 129.048 109.006 129.363C108.327 129.679 107.587 129.843 106.838 129.843H106.845ZM16.2028 54.4043L40.1634 77.7612C40.7592 78.342 41.2053 79.0586 41.4634 79.8496C41.7215 80.6406 41.7839 81.4824 41.6453 82.3029L35.9887 115.282L65.599 99.7219C66.3392 99.3314 67.1634 99.1273 68.0002 99.1273C68.8371 99.1273 69.6613 99.3314 70.4014 99.7219L100.019 115.292L94.3552 82.3029C94.2144 81.4811 94.2754 80.6375 94.5329 79.8445C94.7905 79.0516 95.2369 78.3331 95.8336 77.7509L119.798 54.4043L86.685 49.6019C85.8599 49.4819 85.0763 49.1632 84.4017 48.6732C83.727 48.1832 83.1816 47.5366 82.8122 46.789L68.0002 16.7842L53.1916 46.789C52.822 47.537 52.276 48.1839 51.6007 48.6739C50.9255 49.1639 50.1412 49.4824 49.3154 49.6019L16.2028 54.4043Z"
        fill="black"
      />
    </svg>
  );
};

export default StarIcon;
