import React, { useEffect, useState } from "react";
import StyledUserProflePage from "./style";
import CustomTreeView from "../CustomTreeView";
import Company from "../../controllers/company";
import ProfileCard from "../ProfileCard";
import User from "../../controllers/user";
import EditProfileCard from "../EditProfileCard";
import ShuffleIcon from "../../assets/icons/ShuffleIcon";
import MiniButton from "../MiniButton";
import AddIcon from "../../assets/icons/AddIcon";

const UserProfilePage = ({ selectUser = () => {} }) => {
  const [treeData, setTreeData] = useState({ name: "", children: [] });
  const [defaultProfiles, setDefaultProfiles] = useState(null);
  const [activeDB, setActiveDB] = useState(null);
  const [customProfiles, setCustomProfiles] = useState([]);
  const [showAddProfile, setShowAddProfile] = useState(false);
  const [showCompanyList, setShowCompanyList] = useState(true);

  const getCompanyTree = async () => {
    const tree = await Company.getCompanyTree();
    setTreeData(tree);
  };

  const getUserProfiles = async (dbName, env) => {
    const profiles = await User.getUserProfiles(dbName, env);
    console.log("profiles", profiles);
    setDefaultProfiles(profiles);
  };

  const getCustomProfiles = () => {
    if (activeDB === null) return;
    const { name, metadata } = activeDB || {};
    const { env } = metadata;
    const customProfiles = Company.getCompanyCustomProfiles(name, env);
    console.log("custom profiles", customProfiles);
    setCustomProfiles(customProfiles);
  };

  useEffect(() => {
    getCompanyTree();
  }, []);

  useEffect(() => {
    if (activeDB !== null) {
      getCustomProfiles();
    }
  }, [activeDB]);

  const handleSelectDb = async (db) => {
    console.log("selected db", db);
    const { name, metadata } = db || {};
    const { env } = metadata;
    setActiveDB(db);
    getUserProfiles(name, env);
    setShowCompanyList(false);
  };

  const handleSaveUserProfile = (user) => {
    const customProfiles = Company.saveUserProfile(user);
    if (customProfiles) {
      console.log("custom profiles", setCustomProfiles);
      setCustomProfiles(customProfiles);
      setShowAddProfile(false);
    }
  };

  const handleCloseAddProfile = () => {
    setShowAddProfile(false);
  };

  const handleAddToFavourite = (user) => {
    if (!user) return;
    const updatedCustomProfiles = user.addToFavourite();
    console.log("customprofiles", updatedCustomProfiles);
    setCustomProfiles(updatedCustomProfiles);
  };

  const handleDeleteProfile = (user) => {
    if (!user) return;
    const updatedProfiles = user.deleteProfile();
    setCustomProfiles(updatedProfiles);
  };

  const handleToggleShowCompanyList = () => {
    setShowCompanyList((prev) => !prev);
  };

  return (
    <StyledUserProflePage>
      <div className={`companies-list ${showCompanyList ? "show" : "hide"}`}>
        <div
          className={`tree-view-container ${showCompanyList ? "show" : "hide"}`}
        >
          <CustomTreeView data={treeData} onSelect={handleSelectDb} />
        </div>
        <div
          onClick={() => handleToggleShowCompanyList()}
          className="toggle-bar"
        ></div>
      </div>

      {activeDB ? (
        <div className="profiles-list-wrapper">
          {defaultProfiles !== null && (
            <div className="profiles-sub-list">
              <div className="title">
                <span>Default Profiles</span>
                <MiniButton
                  title="Shuffle"
                  icon={<ShuffleIcon width="15" height="15" />}
                  onClick={() => handleSelectDb(activeDB)}
                />
              </div>
              <div className="cards-list">
                <ProfileCard
                  addToFavourite={handleAddToFavourite}
                  start={() => selectUser(defaultProfiles?.operatorProfile)}
                  activeDb={activeDB}
                  user={defaultProfiles?.operatorProfile}
                  isDefault
                />
                <ProfileCard
                  addToFavourite={handleAddToFavourite}
                  start={() => selectUser(defaultProfiles?.memberProfile)}
                  activeDb={activeDB}
                  user={defaultProfiles?.memberProfile}
                  isDefault
                />
              </div>
            </div>
          )}

          <div className="profiles-sub-list">
            <div className="title">
              <span>Custom Profiles</span>
              <MiniButton
                disabled={showAddProfile}
                onClick={() => setShowAddProfile(true)}
                title="New Profile"
                icon={
                  <AddIcon
                    width="12"
                    height="12"
                    color={showAddProfile ? "#aaa" : "#000"}
                  />
                }
              />
            </div>
            <div className="cards-list">
              {showAddProfile && (
                <EditProfileCard
                  close={handleCloseAddProfile}
                  activeDb={activeDB}
                  saveProfile={handleSaveUserProfile}
                />
              )}

              {Array.isArray(customProfiles) && customProfiles.length > 0
                ? customProfiles.map((profile) => (
                    <ProfileCard
                      key={profile.id}
                      deleteProfile={handleDeleteProfile}
                      addToFavourite={handleAddToFavourite}
                      start={() => selectUser(profile)}
                      user={profile}
                    />
                  ))
                : !showAddProfile && (
                    <div>
                      <span>No saved profiles.</span>
                      <br />
                      <button>Add a profile</button>
                    </div>
                  )}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-company-selected">Select a company</div>
      )}
    </StyledUserProflePage>
  );
};

export default UserProfilePage;
